import React, { ReactElement } from "react";
import dynamic from "next/dynamic";
import MainBanner from "@components/contentintelligence/mainBanner";
import { useDomainContext } from "@hooks/useDomainContext";

const ProductiveTools = dynamic(() => import("../productiveTools"));
const WhyUs = dynamic(() => import("@components/contentintelligence/whyUs"));
const EcmDetails = dynamic(
    () => import("@components/contentintelligence/ecmDetails/ecmDetails"),
);
const MakeItEasy = dynamic(
    () => import("@components/contentintelligence/makeItEasy"),
);
const OffersList = dynamic(() => import("@components/shared/offersList"));
export default function HomePage(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
            <EcmDetails />
            <MakeItEasy />
            <ProductiveTools />
        </>
    );
}
