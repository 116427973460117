import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/contentintelligenceVariables.module.scss";
import CheckCircleIcon from "@components/svg/checkCircleIcon";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const sellingPoints = [
        "Capture and secure your documents from day one.",
        "Track and leverage your valuable information.",
        "Content is available to everyone you've authorized.",
        "Automate repetitive tasks so your documents, approvals, and actionable items get to the right people at the right time.",
    ];
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles["main-banner"]}>
                    <div className={styles["intro"]}>
                        <h1>
                            Start Your Organization's Digital Transformation{" "}
                        </h1>
                        <p>
                            Through powerful workflows, electronic forms,
                            document management and analytics, our platform
                            eliminates manual processes and automates repetitive
                            tasks, accelerating how business gets done in a
                            secure way.
                        </p>
                        <p>
                            Our top-rated enterprise content management
                            companies can help you enjoy the following benefits:
                        </p>
                        <ul>
                            {sellingPoints.map((point, index) => (
                                <li key={index}>
                                    <span className="">
                                        <CheckCircleIcon />
                                    </span>
                                    <span>{point}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Form
                        classes={{
                            formClassName: styles["form"],
                            stepTitleClassName: styles["step-title"],
                            stepClassName: styles["step-block"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formStepInnerClassName: styles["steps-inner"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                                checkbox: styles["checkbox-field"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                            sellingPointClassName: styles["selling-point"],
                        }}
                        colors={{
                            primaryColor: colors.purple,
                            progressBar: colors.grey,
                        }}
                        showProgress={true}
                        preventWindowClose
                    />
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
