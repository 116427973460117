import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="none"
            viewBox="0 0 15 15"
        >
            <circle cx="7.5" cy="7.5" r="7.5" fill="#000"></circle>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.375 7.948l1.989 1.989 4.198-4.198"
            ></path>
        </svg>
    );
}

export default Icon;
